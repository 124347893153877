<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar class="px-1 bg-black toolbar-title">
        <div slot="start" class="back-images-btn" @click="goBack">
          <ion-icon class="text-white" slot="icon-only" :icon="chevronBackOutline"></ion-icon>
        </div>
        <ion-label class="ml-2 text-white">{{ $t('back') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content
      :scroll-y="true"
      :scroll-x="true"
      color="dark"
      :fullscreen="true"
      class="ion-no-padding bg-black"
      ref="imageContent"
    >
      <div :class="{ 'preview-image': true, zoomed: isZoomed }">
        <ion-img
          :style="isZoomed ? `transform: scale(${zoomLevel})` : ''"
          :src="showSelectedImage"
          :class="{ 'zoom-image': true, zoomed: isZoomed }"
          @click="zoomingImage"
        ></ion-img>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar class="px-1 py-1 bg-black">
        <div class="row end px-1 my-2">
          <ion-icon
            class="text-white prev-next-btn"
            slot="icon-only"
            :icon="chevronBackCircleOutline"
            @click="selectPreviousImage"
          ></ion-icon>
          <ion-icon
            class="text-white prev-next-btn"
            slot="icon-only"
            :icon="chevronForwardCircleOutline"
            @click="selectNextImage"
          ></ion-icon>
        </div>
        <ion-slides class="slides-cate" :options="slideOpts">
          <ion-slide>
            <div v-for="(item, index) in images" :key="index" class="mx-1 img-cover">
              <ion-img
                class="small-img"
                :class="item === showSelectedImage && imageIndex === index ? 'selected-image' : ''"
                :src="item"
                @click="selectedImage(item, index)"
              ></ion-img>
            </div>
          </ion-slide>
        </ion-slides>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import { chevronBackCircleOutline, chevronBackOutline, chevronForwardCircleOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },

  emits: ['close-page'],
  mounted() {
    this.selectedImage(this.images[0], 0);
  },

  setup(props, { emit }) {
    const isTrackingLinkNotValid = ref(false);
    const goBack = () => {
      emit('close-page');
    };
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 'auto'
    };

    const showSelectedImage = ref('');
    const imageIndex = ref(0);
    const selectedImage = (selectedImg, index) => {
      // set zoom back to default first before select/show other image
      zoomLevel.value = 1;
      zoomImage(false);
      (showSelectedImage.value = selectedImg), (imageIndex.value = index);
    };

    const selectNextImage = () => {
      // set zoom back to default first before select/show other image
      zoomLevel.value = 1;
      zoomImage(false);
      imageIndex.value = (imageIndex.value + 1) % props.images?.length;
      selectedImage(props.images[imageIndex.value], imageIndex.value);
    };

    const selectPreviousImage = () => {
      // set zoom back to default first before select/show other image
      zoomLevel.value = 1;
      zoomImage(false);
      imageIndex.value = (imageIndex.value - 1 + props.images?.length) % props.images?.length;
      selectedImage(props.images[imageIndex.value], imageIndex.value);
    };

    const isZoomed = ref(false);
    const imageContent = ref(null);
    const zoomImage = (params) => {
      isZoomed.value = params;
    };
    const setImageToCenter = () => {
      if (imageContent.value) {
        const contentElement = imageContent.value.$el;
        const contentImageHeight = contentElement.clientHeight;
        const contentImageWidth = contentElement.clientWidth;

        // Calculate the center position
        const centerX = contentImageWidth / 2;
        const centerY = contentImageHeight / 2;

        // Scroll to the center position
        contentElement.scrollToPoint(centerX, centerY, 500);
      }
    };
    // default zoom transform is 1
    const zoomLevel = ref(1);
    const zoomingImage = () => {
      if (zoomLevel.value === 1) {
        setImageToCenter();
        // on first touch/click will zoom image and set transform to 2.3
        zoomLevel.value = 2.3;
        zoomImage(true);
      } else {
        // on second touch/click will set zoom image to deafult 1
        zoomLevel.value = 1;
        zoomImage(false);
      }
    };
    return {
      chevronBackOutline,
      chevronBackCircleOutline,
      chevronForwardCircleOutline,
      isTrackingLinkNotValid,
      goBack,
      slideOpts,
      selectedImage,
      showSelectedImage,
      imageIndex,
      isZoomed,
      zoomLevel,
      imageContent,
      zoomImage,
      zoomingImage,
      setImageToCenter,
      selectNextImage,
      selectPreviousImage
    };
  }
});
</script>
<style src="../style.scss" lang="scss" scoped></style>
